<template>
    <b-card no-body>
      <validation-observer v-slot="{ handleSubmit }" ref="VForm">
        <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD('VForm', $refs))">
          <b-card-header>
            <b-row>
              <b-col lg="8">
                <h5 class="card-title">{{ $parent.isAdd?'Add':'Edit' }} {{ $parent.pageTitle }}</h5>
              </b-col>
            </b-row>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col lg="8">
                <b-row>
                  <b-col lg="6">
                    <b-form-group>
                      <label>Surveyor Name <span class="text-danger mr5">*</span></label>
                      <b-form-input v-model="row.ms_name" placeholder="e.g Surveyor Name">
                      </b-form-input>
                      <VValidate name="Surveyor Name" v-model="row.ms_name" :rules="mrValidation.ms_name" />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group>
                      <label>Mobile Number  <span class="text-danger mr5">*</span></label>
                      <b-form-input v-model="row.ms_phone" type="number" :formatter="$parent.telp" placeholder="e.g Mobile Number"></b-form-input>
                      <VValidate name="Mobile Number" v-model="row.ms_phone" :rules="{regex:/^(\+\d{1,3}[- ]?)?\d{10,13}$/, required: true}" />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group class="">
                      <label>Status<span class="text-danger mr5">*</span></label>
                      <b-form-radio-group :options="Config.mr.StatusOptions" v-model="row[$parent.statusKey]" />
                      <VValidate name="Status" v-model="row.ms_address" :rules="mrValidation.ms_status" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-footer>
            <b-row>
              <b-col lg="12" class="text-right">
                <button @click="$router.back()" type="button" class="btn btn-rounded btn-default mr-3">Cancel</button>
                <b-button type="submit" variant="success" class="btn-rounded">Submit</b-button>
              </b-col>
            </b-row>
          </b-card-footer>
        </b-form>
      </validation-observer>
    </b-card>
</template>
<script>
 export default {
    props: {
      row: Object,
      mrValidation: Object,
      Config: Object,
    },
    methods: {
      doSubmitForm() {
        this.$emit('click:doSubmit', 'VForm', this.$refs)
      },
    }
 }
</script>
